import React, { Fragment } from 'react'
import { Link } from 'gatsby'
import { ContainerFlexedContent, Container } from './styled'
import styled from 'styled-components'
import MoonSVG from '../images/moon.svg'

const HeaderContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  position: fixed;
  top: 0;
  width: 100%;
  margin: 0 auto;
  z-index: 3;
  left: 50%;
  transform: translateX(-50%);
`
const OffScreen = styled.span`
  position: relative;
  display: inline-block;
  text-indent: -9999px;
  width: 0px;
`
const Header = () => (
  <HeaderContainer>
    <Link to="/" style={{ textDecoration: 'none' }}>
      <h1>
        P<OffScreen>addy </OffScreen>H<OffScreen>amilton</OffScreen>
      </h1>
    </Link>
    {/* <img src={MoonSVG} alt="moon svg" /> */}
  </HeaderContainer>
)

export default Header
