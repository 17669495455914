const theme = {
  colours: {
    primary: '#ffffff',
    secondary: '#000',
  },
  font: {
    fontFamily: `'Open Sans', sans-serif`,
    fontSize: '14px',
  },
  h1: {
    fontSize: '40px',
    fontFamily: `'Sansita', sans-serif`,
    fontWeight: 900,
    lineHeight: '48px',
    marginTop: 0,
  },
  h2: {
    fontWeight: 700,
    fontSize: '48px',
    fontFamily: `'Sansita', sans-serif`,
  },
  h4: {
    fontWeight: 700,
    fontSize: '18px',
    fontFamily: `'Sansita', sans-serif`,
  },
  p: {
    fontSize: '18px',
    lineHeight: '24px',
  },
  breakpoints: {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px',
  },
}

//* based on https://jsramblings.com/2018/02/04/styled-components-media-queries.html
export const devices = {
  mobileS: `(min-width: ${theme.breakpoints.mobileS})`,
  mobileM: `(min-width: ${theme.breakpoints.mobileM})`,
  mobileL: `(min-width: ${theme.breakpoints.mobileL})`,
  tablet: `(min-width: ${theme.breakpoints.tablet})`,
  laptop: `(min-width: ${theme.breakpoints.laptop})`,
  laptopL: `(min-width: ${theme.breakpoints.laptopL})`,
  desktop: `(min-width: ${theme.breakpoints.desktop})`,
  desktopL: `(min-width: ${theme.breakpoints.desktop})`,
}

export default theme
