import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components'
import Footer from './Footer'
import theme from '../theme'
import { Container } from './styled'
import { Normalize } from 'styled-normalize'

const GlobalStyle = createGlobalStyle(p => ({
  body: {
    backgroundColor: p.theme.colours.primary,
    ...p.theme.font,
    color: p.theme.colours.secondary,
    height: '100%',
    width: '100%',
    margin: 0,
  },
  '#___gatsby': {
    height: '100%',
    width: '100%',
    '& > div': {
      height: '100%',
      width: '100%',
    },
  },
  html: {
    height: '100%',
    width: '100%',
    boxSizing: 'border-box',
    '& *': {
      boxSizing: 'border-box',
    },
  },
  h1: {
    ...p.theme.h1,
  },
  h2: {
    ...p.theme.h2,
  },
  p: {
    ...p.theme.p,
  },
  a: {
    color: p.theme.colours.secondary,
  },
}))
const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            {
              name: 'description',
              content:
                'Paddy Hamilton || Front-End Developer & UI Designer based in Glasgow',
            },
            {
              name: 'keywords',
              content: 'Front-End Developer, Glasgow, UI, Design, Software,',
            },
          ]}
        >
          <link
            href="https://fonts.googleapis.com/css?family=Open+Sans|Sansita:700&display=swap"
            rel="stylesheet"
          />

          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=UA-61548254-2"
          />
          <script>
            {`            
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'UA-61548254-2');
            `}
          </script>
          <html lang="en" />
        </Helmet>
        <ThemeProvider theme={theme}>
          <>
            <Normalize />
            <GlobalStyle />
            {children}
            <Footer />
          </>
        </ThemeProvider>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
