import React from 'react'
import styled from 'styled-components'
import { devices } from '../theme'
export const Container = styled.div(({ fullWidth }) => ({
  margin: '0 auto',
}))
export const ContainerFlexedContent = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const ContainerFullScreen = styled(Container)`
  height: 100vh;
  width: 100%;
`

export const VerticleaAligned = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`
export const ColourGradient = styled.div(props => {
  return {
    background: Array.isArray(props.colours)
      ? `linear-gradient(${props.colours.join()});`
      : props.colours,
    width: '50%',
    borderRadius: '100%',
    height: '50%',
    minHeight: '1px',
    margin: '0 auto',
    position: 'absolute',
    left: '50%',
    top: '50%',
    zIndex: -1,

    transform: 'translate(-50%,-50%) ',
  }
})

export const SplitLayoutItem = styled.div`
  height: 100vh;
  width: 100%;
  @media ${devices.laptop} {
    width: 50%;
  }
`
export const SplitLayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media ${devices.laptop} {
    flex-direction: row;
  }
`

export const IntroP = styled.p`
  font-size: 24px;
  line-height: 33px;
`
