import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { ContainerFlexedContent } from './styled'

const StyledFooter = styled(ContainerFlexedContent)`
  position: fixed;
  bottom: 0;
  padding: 2rem;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  justify-content: center;
  z-index: 9999;
`

const Footer = () => (
  <StyledFooter>
    <a
      style={{ textDecoration: 'none', fontSize: '18px' }}
      href="mailto:hello@paddyhamilton.cool"
    >
      hello@paddyhamilton.cool
    </a>
  </StyledFooter>
)

export default Footer
